import Rails from "rails-ujs";

export default class Dashboard {
  constructor($dashboardPage) {
    this.$dashboardPage = $dashboardPage;
    this.$lineChart = this.$dashboardPage.find('#line-chart');
    this.$officeSelector = this.$dashboardPage.find('.js-office-selector');
    this.$productSpanSelector = this.$dashboardPage.find('.js-product_span-selector');
    this.$productSpanForm = this.$dashboardPage.find('.js-product_span-form');
    this.$chartTypeButtons = this.$dashboardPage.find('.js-chart_type');
    this.unbindDashboardEvents();
    this.bindOfficeSelectorEvent();
    this.bindProductSpanSelectorEvent();
    this.bindChartTypeButtonEvent();
    this.changeProductSpanSelectorColor();
  }

  get corporationId() {
    return this.$officeSelector.data('corporation-id');
  }

  get officeId() {
    return this.$officeSelector.val();
  }

  get chartType() {
    return this.$dashboardPage.find('.js-chart_type.active').data('type');
  }

  get requestMonthlyOrderAggregationParams() {
    return {target: this.chartType, corporation_id: this.corporationId, office_id: this.officeId};
  }

  lineData(thisYears, prevYears) {
    return {
      labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      datasets: [
        {
          label: '前年',
          borderColor: "#8ED9FF",
          pointRadius: 2,
          borderWidth: 1,
          backgroundColor: "#b6e2ff73",
          pointBackgroundColor: "#8ED9FF",
          data: prevYears
        },
        {
          label: '当年',
          borderColor: "#2b52ab",
          pointRadius: 2,
          borderWidth: 1,
          backgroundColor: "#2b52ab2b",
          pointBackgroundColor: "#2b52ab",
          data: thisYears
        }
      ]
    };
  }

  chartParams(lineData) {
    return {
      type: 'line',
      data: lineData,
      options: {
        // title: {
        //   display: true,
        //   text: '注文'
        // },
        legend: {
          display: true,
        },
        scales: {
          xAxes: [{
            ticks: {
              fontSize: '11',
              fontColor: '#969da5'
            },
            gridLines: {
              color: '#f6f8fd',
              zeroLineColor: '#f6f8fd'
            }
          }],
          yAxes: [{
            gridLines: {
              color: '#f6f8fd',
              zeroLineColor: '#f6f8fd'
            },
            ticks: {
              fontSize: '11',
              fontColor: '#969da5'
            }
          }]
        }
      }
    };
  }

  bindOfficeSelectorEvent() {
    this.$officeSelector.on('change', function () {
      let href = location.href.replace(/\?.+/, '');
      if (this.officeId)
        href = '{0}?office_id={1}'.format(href, this.officeId);
      window.location.href = href;
    }.bind(this))
  }

  bindProductSpanSelectorEvent() {
    this.$productSpanSelector.on('change', function () {
      this.changeProductSpanSelectorColor();
      Rails.fire(this.$productSpanForm[0], 'submit');
    }.bind(this));
  }

  bindChartTypeButtonEvent() {
    this.$chartTypeButtons.on('click', function (e) {
      e.preventDefault();
      this.$chartTypeButtons.removeClass('active');
      const type = $(e.target).data('type');
      this.$dashboardPage.find('.js-chart_type[data-type="{0}"]'.format(type)).addClass('active');
      this.loadChart();
      return false;
    }.bind(this));
  }

  unbindDashboardEvents() {
    this.$officeSelector.off();
    this.$productSpanSelector.off();
    this.$chartTypeButtons.off();
  }

  changeProductSpanSelectorColor() {
    const color = this.$productSpanSelector.val() === 'this_month' ? '#E33244' : '#000';
    const weight = this.$productSpanSelector.val() === 'this_month' ? 500 : 300;
    this.$productSpanSelector.css({'color': color, 'font-weight': weight});
  }

  loadChart() {
    $.ajax({
      url: '/api/monthly_order_aggregations',
      data: this.requestMonthlyOrderAggregationParams,
      type: 'GET',
      dataType: 'json',
      cache: false,
      success: function (data, textStatus, jqXHRn) {
        // console.log(JSON.stringify(data));
        this.onUpdateOrderChart(data.this_year, data.prev_year)
      }.bind(this)
    });
  }

  onUpdateOrderChart(thisYears, prevYears) {
    let lineData = this.lineData(thisYears, prevYears);
    new Chart(this.$lineChart, this.chartParams(lineData));
  }
}
