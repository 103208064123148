export default class NotificationUserOffices {
  constructor($notificationTable) {
    this.$notificationTable = $notificationTable;
    this.$allCheckbox = $notificationTable.find('.js-all-office-cbx');
    this.$officeCheckboxes = $notificationTable.find('.js-office-cbx');

    this.bindAllCheckboxEvent();
    this.bindOfficeCheckboxEvent();
  }

  // 全選択チェックボックスの更新イベント（全拠点の受信可否を更新）
  bindAllCheckboxEvent() {
    this.$allCheckbox.on('change', function (e) {
      e.preventDefault();
      let checked = this.$allCheckbox.prop('checked');
      let userId = this.$allCheckbox[0].dataset.userId;
      this.requestAllAtOnce(userId, checked, function (data) {
        this.updateAllOfficeCheckbox(data);
      }.bind(this));
      return false;
    }.bind(this));
  }

  // 拠点チェックボックスの更新イベント（対象拠点の受信可否を更新）
  bindOfficeCheckboxEvent() {
    this.$officeCheckboxes.on('change', function (e) {
      e.preventDefault();
      let $checkbox = $(e.target);
      let checked = $checkbox.prop('checked');
      if (checked) {
        let userId = $checkbox[0].dataset.userId;
        let officeId = $checkbox[0].dataset.officeId;
        this.requestCreateNotification(userId, officeId, function (id) {
          $checkbox[0].dataset.id = id;
        });
      } else {
        let id = $checkbox[0].dataset.id;
        this.requestDestroyNotification(id, function () {
          delete $checkbox[0].dataset.id;
        });
      }
      return false;
    }.bind(this));
  }

  // 受信拠点の一括登録または一括削除
  requestAllAtOnce(userId, allow, callback) {
    $.ajax({
      url: '/api/notification_user_offices/all_at_once',
      data: {user_id: userId, allow: allow},
      type: 'POST',
      dataType: 'json',
      cache: false,
      success: function (data, textStatus, jqXHRn) {
        // console.log(JSON.stringify(data));
        callback(data);
      }
    });
  }

  // 受信拠点の登録
  requestCreateNotification(userId, officeId, callback) {
    $.ajax({
      url: '/api/notification_user_offices',
      data: {user_id: userId, office_id: officeId},
      type: 'POST',
      dataType: 'json',
      cache: false,
      success: function (data, textStatus, jqXHRn) {
        // console.log(JSON.stringify(data));
        callback(data.id);
      }
    });
  }

  // 受信拠点の削除
  requestDestroyNotification(id, callback) {
    $.ajax({
      url: '/api/notification_user_offices/{0}'.format(id),
      type: 'DELETE',
      dataType: 'json',
      cache: false,
      success: function (data, textStatus, jqXHRn) {
        // console.log(JSON.stringify(data));
        callback();
      }
    });
  }

  // 拠点チェックボックスのdata属性、選択状態を一括更新
  updateAllOfficeCheckbox(data) {
    this.$officeCheckboxes.prop('checked', data.allow);
    delete this.$officeCheckboxes[0].dataset.id;
    $.each(data.notifications, function (index, notification) {
      let condition = '.js-office-cbx[data-office-id="{0}"]'.format(notification.office_id);
      let $checkbox = this.$notificationTable.find(condition);
      if ($checkbox.length) $checkbox[0].dataset.id = notification.id;
    }.bind(this));
  }
}
