String.prototype.format = function (arg) {
  var repFunc = undefined;
  if (typeof arg === 'object') {
    repFunc = function (m, k) {
      return arg[k];
    }
  } else {
    var args = arguments;
    repFunc = function (m, k) {
      return args[ parseInt(k) ];
    }
  }
  return this.replace(/\{(\w+)\}/g, repFunc);
};
