import Rails from 'rails-ujs';
Rails.start();

import 'flatpickr/dist/flatpickr';
import '../application/extensions'
import OrderForm from './order_form'
import NotificationUserOffices from '../application/notification_user_offices'
import Dashboard from "../user/dashboard";

window.Dashboard = Dashboard;

$(function () {
  // ダブルサブミット対策
  $('form').submit(function () {
    const self = this;
    $(':submit', self).prop('disabled', true);
    setTimeout(function () {
      $(':submit', self).prop('disabled', false);
    }, 5000);
  });

  // フォームの戻るボタンをクリック
  $(document).on('click', 'input.js-back-action', function () {
    $(":hidden[name='state']").val('back');
  });

  // reCAPTCHA の token を取得して送信
  $('.js-recaptcha-v3-form input[type=submit]').on('click', function (e) {
    e.preventDefault();
    const $btn = $(this);
    const $input = $('.js-recaptcha_token');
    try {
      $btn.prop('disabled', true);
      const siteKey = $input.data('site-key');
      const action = $input.data('action');
      grecaptcha.ready(function () {
        grecaptcha.execute(siteKey, {action: action})
          .then(function (token) {
            $input.val(token);
            $btn.parents('form').submit();
          }, function (reason) {
            // console.log(reason);
            $btn.prop('disabled', false);
          });
      });
    } catch (e) {
      // console.log(e);
      $btn.prop('disabled', false);
    }
    return false;
  });

  // 日付選択 - flatpickr
  $(".flatpickr").flatpickr();

  // CSVダウンロード
  $('.js-search-form .js-csv-download').on('click', function (e) {
    e.preventDefault();
    const $form = $('.js-search-form');
    let $format = $('input#format', $form);

    $(this).addClass('disabled');
    $format.val('csv');
    $form.submit();
    $format.val('html');
    $(this).removeClass('disabled');
    return false;
  });

  // 全てのセレクト項目に削除イベントを発行して検索ドロップダウンを表示 - Select2
  let clearOpenSelector = function () {
    $(this).off('select2:open');
    $('.js-reference-selector').select2('close');
    $(this).select2('open');
    $(this).on('select2:open', clearOpenSelector);
  };

  // 関連レコードの検索 - Select2
  $('.js-reference-selector').each(function (i, e) {
    let $selector = $(e);
    $selector.select2({
      ajax: {
        url: $selector.data('end-point'),
        dataType: 'json',
        delay: 50,
        data: function (params) {
          return {
            search: params.term
          };
        },
      },
      placeholder: $selector.data('placeholder'),
      minimumInputLength: 1,
      multiple: false,
      allowClear: true,
      language: {
        errorLoading: function () {
          return '結果が読み込まれませんでした'
        }, inputTooLong: function (e) {
          var t = e.input.length - e.maximum;
          return t + ' 文字を削除してください'
        }, inputTooShort: function (e) {
          var t = e.minimum - e.input.length;
          return t + ' 文字以上の入力が必要です'
        }, loadingMore: function () {
          return '読み込み中…';
        }, maximumSelected: function (e) {
          return '選択できるのは ' + e.maximum + ' 件までです';
        }, noResults: function () {
          return '対象が見つかりません';
        }, searching: function () {
          return '検索しています…';
        }, removeAllItems: function () {
          return 'すべてのアイテムを削除';
        }
      }
    });
    $selector.on('select2:open', clearOpenSelector);
  });

  // Resize Select2 width
  $(window).on('resize', function () {
    $('.js-ref-container').each(function () {
      let formGroup = $(this);
      formGroup.find('.select2-container').css('width', formGroup.innerWidth());
    });
  });

  // 検索フォームの表示、非表示
  $('.js-search-button').on('click', function () {
    $('.collapse').collapse('toggle');
  });

  // 受信拠点
  let $notificationTable = $('.js-notification_user_offices-table');
  if ($notificationTable.length) new NotificationUserOffices($notificationTable);

  // 注文登録、編集
  let $orderForm = $('.js-order-form');
  if ($orderForm.length) new OrderForm($orderForm);

  // 注文請書、納品書、請求書、請求明細の自動ダウンロード
  let $downloadLink = $('.js-auto-download');
  if ($downloadLink.length) window.location.href = $downloadLink.attr('href');

  // お問い合わせメッセージの投稿確認時はページ下部までスクロール
  let $confirmForm = $('.js-bottom-confirmation-form');
  if ($confirmForm.length) {
    const a = document.documentElement;
    const y = a.scrollHeight - a.clientHeight;
    window.scroll(0, y);
  }

  // ダッシュボード
  let $dashboardPage = $('body.user_top_index');
  if ($dashboardPage.length) {
    let dashboard = new Dashboard($dashboardPage);
    dashboard.loadChart();
  }
});
