export default class OrderForm {
  constructor($form) {
    this.$form = $form;
    this.$car = $form.find('.js-car');
    this.$office = $form.find('.js-office');
    this.$salesPrices = $form.find('.js-sales_prices');
    this.$orderDetailContainer = $form.find('.js-order_detail-container');
    this.$productMissingAlert = $form.find('.js-product-missing-alert');
    this.$contactLink = $form.find('.js-order-contact-link');

    this.$productData = $('.js-product_data');
    this.$detailData = $('.js-detail_data');
    this.$templateRow = $('.js-template .form-row');

    this.bindCarEvent();
    this.bindOfficeEvent();
    this.bindSalesPricesEvent();
  }

  // 商品、販売価格情報を取得するリクエストパラメータ（注文先、ボディ分類、カラー分類）
  get requestSalesPriceParams() {
    return {office_id: this.officeId, body_type_id: this.bodyTypeId};
  }

  // 既に登録済である現在の注文明細で販売価格IDをキーとしたハッシュを取得
  get detailData() {
    let data = {};
    this.$detailData.each(function () {
      data[$(this).data('sales-price-id')] = {
        'detail-id': $(this).data('detail-id'),
        'product-name': $(this).data('product-name'),
        'total-amount': $(this).data('total-amount')
      };
    });
    return data;
  }

  // 配置済の注文明細の入力項目に割り当てられた販売価格IDを一覧で取得
  get existDetailSalePriceIds() {
    let removeIds = [];
    this.$orderDetailContainer.find('.form-row').each(function () {
      removeIds.push($(this).attr('id'))
    });
    return removeIds;
  }

  // 注文先IDの取得
  get officeId() {
    return this.$productData.data('office-id');
  }

  // 注文先IDの設定
  set officeId(id) {
    this.$productData.data('office-id', id);
  }

  // ボディタイプの取得
  get bodyTypeId() {
    return this.$productData.data('body-type-id');
  }

  // 車両更新イベント（車両の変更後、リクエストパラメータを付与してリロード）
  bindCarEvent() {
    this.$car.on('change', function (e) {
      e.preventDefault();
      window.location.href = this.$car.data('target') + '/?car_id={0}'.format(this.$car.val());
      return false;
    }.bind(this));
  }

  // 注文先の更新イベント（注文先の変更時に商品一覧を更新）
  bindOfficeEvent() {
    let self = this;
    this.$office.on('change', function () {
      self.officeId = self.$office.val();

      self.updateOrderContactLink();
      self.clearSalesPrices();
      self.hideProductMissingAlert();
      self.clearDetailFormRows();
      if (self.officeId) self.requestSalesPrices();
    });
  }

  // 商品一覧の選択イベント（商品の選択時に注文明細の入力項目を更新）
  bindSalesPricesEvent() {
    let self = this;
    this.$salesPrices.on('change', function () {
      let removeSalesPriceIds = self.existDetailSalePriceIds;

      self.$salesPrices.children('option:selected').each(function () {
        let $option = $(this);
        const selectedId = 'sales-price-{0}'.format($option.val());
        if ($('#{0}'.format(selectedId)).length) {
          removeSalesPriceIds = removeSalesPriceIds.filter(function (salePriceId) {
            return salePriceId !== selectedId;
          });
        } else {
          self.appendDetailFormRow($option);
        }
      });
      self.removeDetailFormRow(removeSalesPriceIds);
    })
  }

  // 商品が存在しない場合のアラートを表示
  showProductMissingAlert() {
    this.$productMissingAlert.removeClass('d-none');
  }

  // 商品が存在しない場合のアラートを非表示
  hideProductMissingAlert() {
    this.$productMissingAlert.addClass('d-none');
  }

  // 注文明細の入力項目を全て破棄
  clearDetailFormRows() {
    this.$orderDetailContainer.empty();
  }

  // 販売価格、商品情報を元に注文明細の入力項目を追加
  appendDetailFormRow($option) {
    const salesPriceId = $option.val(),
      productName = $option.text();

    const data = this.detailData[salesPriceId];
    const totalAmount = data ? data['total-amount'] : $option.data('total-amount');
    const detailId = data ? data['detail-id'] : null;

    let $row = this.$templateRow.clone();
    $row.attr('id', 'sales-price-{0}'.format(salesPriceId));

    // id, name
    const detailAttrId = 'form_details_attributes_{0}'.format(salesPriceId);
    const detailAttrName = 'form[details_attributes][{0}]'.format(salesPriceId);

    // 注文明細ID
    if (detailId) {
      const detailIdId = '{0}_id'.format(detailAttrId);
      const detailIdName = '{0}[id]'.format(detailAttrName);
      $row.find('.js-detail_id').attr({id: detailIdId, name: detailIdName}).val(detailId);
    }

    // 販売価格ID
    const salesPriceIdId = '{0}_sales_price_id'.format(detailAttrId);
    const salesPriceIdName = '{0}[sales_price_id]'.format(detailAttrName);
    $row.find('.js-sales_price_id').attr({id: salesPriceIdId, name: salesPriceIdName}).val(salesPriceId);

    // 商品名
    $row.find('.js-product_name-text').text(productName);

    // 単価
    $row.find('.js-total_amount-text').text(totalAmount.toLocaleString() + '円');

    $row.appendTo(this.$orderDetailContainer);
  }

  // 削除対象の販売価格IDが設定された注文明細の入力項目を削除
  removeDetailFormRow(removeSalesPriceIds) {
    $.each(removeSalesPriceIds, function (i, id) {
      this.$orderDetailContainer.children('#{0}'.format(id)).remove();
    }.bind(this));
  };

  // 商品一覧を初期化
  clearSalesPrices() {
    this.$salesPrices.attr('size', 0).empty();
  }

  // 指定の販売価格情報で商品一覧を更新
  updateSalesPrices(prices) {
    this.$salesPrices.attr('size', prices.length);
    $.each(prices, function (index, value) {
      const data = this.detailData[value.id];
      const productName = data ? data['product-name'] : value.product_name;
      let $option = $('<option>').data({'total-amount': value.total_amount});
      $option.val(value.id).text(productName).appendTo(this.$salesPrices);
    }.bind(this));
  }

  // お問い合わせリンクのパラメータを更新
  updateOrderContactLink() {
    let href = this.$contactLink.attr('href').replace(/office_id=\d+/, '');
    href += this.officeId ? 'office_id={0}'.format(this.officeId) : '';
    this.$contactLink.attr('href', href);
  }

  // 商品情報の元となる販売価格情報を取得
  requestSalesPrices() {
    $.ajax({
      url: '/api/sales_prices',
      data: this.requestSalesPriceParams,
      type: 'GET',
      dataType: 'json',
      cache: false,
      success: function (data, textStatus, jqXHRn) {
        // console.log(JSON.stringify(data));
        this.onUpdateSalesPrices(data.results);
      }.bind(this)
    });
  }

  // 販売価格情報の取得に成功した場合、商品一覧を更新
  onUpdateSalesPrices(prices) {
    this.updateSalesPrices(prices);
    if (this.$car.val() && prices.length === 0) this.showProductMissingAlert();
  }
}
